// global-menu-reset.js
document.addEventListener('DOMContentLoaded', function() {
    // Function to reset menu checkboxes
    function resetMenus() {
        const menuCheckboxes = ['active', 'paintings-active'];
        menuCheckboxes.forEach(id => {
            const checkbox = document.getElementById(id);
            if (checkbox) checkbox.checked = false;
        });
    }

    // Prevent multiple menus from being open simultaneously
    function preventSimultaneousMenus() {
        const mainMenuCheckbox = document.getElementById('active');
        const paintingsMenuCheckbox = document.getElementById('paintings-active');

        mainMenuCheckbox.addEventListener('change', () => {
            if (mainMenuCheckbox.checked) {
                paintingsMenuCheckbox.checked = false;
            }
        });

        paintingsMenuCheckbox.addEventListener('change', () => {
            if (paintingsMenuCheckbox.checked) {
                mainMenuCheckbox.checked = false;
            }
        });
    }

    // Reset on initial page load and when navigating back
    window.addEventListener('pageshow', resetMenus);
    
    // Add prevention of simultaneous menu opens
    preventSimultaneousMenus();
});

// ESC key to close wrapper

document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      // Uncheck both checkboxes to ensure both menus are closed
      const activeCheckbox = document.getElementById('active');
      const paintingsCheckbox = document.getElementById('paintings-active');
      
      if (activeCheckbox) {
        activeCheckbox.checked = false;
      }
      if (paintingsCheckbox) {
        paintingsCheckbox.checked = false;
      }
    }
  });
  